// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-tsx": () => import("/opt/build/repo/src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("/opt/build/repo/src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-about-page-tsx": () => import("/opt/build/repo/src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("/opt/build/repo/src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-tags-tsx": () => import("/opt/build/repo/src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("/opt/build/repo/src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-examples-tsx": () => import("/opt/build/repo/src/pages/contact/examples.tsx" /* webpackChunkName: "component---src-pages-contact-examples-tsx" */),
  "component---src-pages-contact-file-upload-tsx": () => import("/opt/build/repo/src/pages/contact/file-upload.tsx" /* webpackChunkName: "component---src-pages-contact-file-upload-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("/opt/build/repo/src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("/opt/build/repo/src/pages/contact/thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("/opt/build/repo/src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

